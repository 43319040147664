import api from '../../../config/Config';
import toast, { Toaster } from 'react-hot-toast';
import requestRow from '../../../controller/PageRow';
import { useEffect, useState, useContext, useRef } from 'react';
// import Popup from '../../components/Popup';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import AuthContext from '../../../authentification/context/AunthLogin';
// import QRCode from 'qrcode';

import {
	Card, Col, Input, Modal, Row, Badge, Dropdown, Space, Table,
	TableColumnsType,
	Popconfirm,
	Button,
	Checkbox
} from 'antd';
import {
	DeliveredProcedureOutlined, SearchOutlined, DownOutlined, DeleteOutlined, EyeOutlined
} from '@ant-design/icons'

// import { Tabs, Tab } from '@mui/material';
// import { TabPanel, TabContext } from '@mui/lab';
// import Barcode from 'react-barcode';
// import html2canvas from 'html2canvas';
// import { saveAs } from 'file-saver';
// import domtoimage from 'dom-to-image';
// import fileSaver from 'file-saver';
// import { BarcodeProps } from 'react-barcode';


interface DataType {
	key: React.Key;
	name: string;
	platform: string;
	version: string;
	upgradeNum: number;
	creator: string;
	createdAt: string;
}

interface ExpandedDataType {
	key: React.Key;
	date: string;
	name: string;
	upgradeNum: string;
}

const items = [
	{ key: '1', label: 'Action 1' },
	{ key: '2', label: 'Action 2' },
];

interface RequestRow {
	id: string;
	is_transfered: string;
	is_transferedAt: string;

}

interface VoucherData {
	observation: string;
	date_expedition: string;
	id_country: string;
	id_serie?: number;
}

function HistoryTransfer() {

	const data = '';

	const styleQr = {
		width: 100,
		height: 100,
	}

	const navigate = useNavigate()
	const { setAuth }: any = useContext(AuthContext);
	const [Loading, setLoading] = useState(false);
	const [page, setPage] = useState(1)
	const [listVoucher, setlistVoucher] = useState<requestRow[]>([]);
	const [loadcountry, setloadCountry] = useState<requestRow[]>([]);
	const [searchResult, setSearchResult] = useState<requestRow[]>([])
	const [historyVoucher, setHistoryVoucher] = useState<requestRow[]>([])
	const [query, setQuery] = useState('');
	const [sorterInfo, setSorterInfo] = useState({});

	const [nombre, setNombre] = useState('');
	const [valeur, setValeur] = useState('');
	const [nombreByPacket, setNomberByPacket] = useState('');
	const [currency, setCurrency] = useState('');
	const [nombreOfSerie, setNomberOfSerie] = useState('');
	const [observation, setOservation] = useState('');
	const [dateEpired, setDateEpired] = useState('');
	const [idCountry, setIdCountry] = useState('');
	const [labelName, setLabelName] = useState('');
	const [serieFrom, setSerieFrom] = useState('00000001');
	const [serieTo, setSerieTo] = useState('00000010');
	const [limit, setLimit] = useState('10');

	const [total, setTotal] = useState('');
	const [totalTransfered, setTotalTransfered] = useState('');
	const [totalNotTransfered, setTotalNotTransfered] = useState('');
	const [totalNotUsed, setTotalNotUsed] = useState('');
	const [totalUsed, setTotalUsed] = useState('');
	const [totalInInterval, setTotalInInterval] = useState('');
	const [countTransfered, setCountTransfered] = useState('');
	const [countNotTransfered, setCountNotTransfered] = useState('');
	const [countUsed, setCountUsed] = useState('');
	const [countNotUsed, setCountNotUsed] = useState('');



	//Search
	const [searchText, setSearchText] = useState("");
	let [filteredData] = useState<requestRow[]>([]);

	//Paginate
	const [totalPage, setTotalPage] = useState(1);


	{/** variable de la pagination */ }
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(15);

	//Affichage du popup
	const [open, setOpen] = useState(false);
	const [openTrans, setOpenTrans] = useState(false);
	const [openQr, setOpenQr] = useState(false);
	function handleClickOpen() {
		setOpen(true);
	};


	const [confirmLoading, setConfirmLoading] = useState(false);
	const [modalText, setModalText] = useState('Content of the modal');

	const showModal = () => {
		setOpen(true);
	};

	const showTransfert = () => {
		setOpenTrans(true);
	};

	// const handleOk = () => {
	// 	setModalText('The modal will be closed after two seconds');
	// 	setConfirmLoading(true);
	// 	setTimeout(() => {
	// 		setOpen(false);
	// 		setConfirmLoading(false);
	// 	}, 2000);
	// };

	const handleCancel = () => {
		console.log('Clicked cancel button');
		setOpen(false);
	};
	const handleCancelTrans = () => {
		setOpenTrans(false);
	}

	// const handleClickOpenQr = async (element: any) => {
	// 	setOpenQr(true);
	// 	setRecuqrcode1(element.series_voucher)
	// 	setReqBarcode(element.series_voucher)
	// 	try {
	// 		const response = await QRCode.toDataURL(element.series_voucher)
	// 		setRecuqrcode(response)

	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// };

	//downloadBarcode 
	// const barcodeProps: BarcodeProps = {
	// 	value: reqBarcode,

	// };


	useEffect(() => {
		if (!localStorage.getItem('token') || localStorage.getItem('token') == null) {
			navigate('/signin')
		}

		List(1);
		loadCountry()
	}, []);

	const List = (page: any) => {
		setLoading(true);
		api.getHttpRequest(`/vouchers-transfert?page=${page}&limit=${limit}`, data, 'GET').then((response) => {
			const result = response.data.data
			setlistVoucher(result.result.rows);
			setSearchResult(result.data);
			setTotalPage(result.result.count);
			setTotal(result.count_success);
			setTotalNotTransfered(result.count_not_transfered);
			setLoading(false);

		}).catch((error) => {
			toast.error(error.message);
		})
	}

	const loadCountry = () => {
		api.getHttpRequest("/country", data, 'GET').then((response) => {
			const result = response.data.data
			setloadCountry(result);

		}).catch((error) => {
			if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
				toast.error("Please connect your device to the internet");
			} else if (error.response && error.response.data && error.response.data.message) {
				toast.error(error.response.data.message);
			} else {
				toast.error(error.message);
			}
		})
	}


	//function export voucher
	// function exportVoucherPrincipal(item: any) {
	// 	let data = JSON.stringify({
	// 		"type": "principal",
	// 		"serie": item
	// 	});

	// 	// setLoading(true);

	// 	api.getHttpRequest('/vouchers/export', data, 'POST').then((response) => {
	// 		if (response.data.status == 200) {
	// 			toast.success(response.data.message);
	// 			List();
	// 			setLoading(true);
	// 		} else {
	// 			// setLoading(false);
	// 			toast.error(response.data.message);
	// 		}
	// 	}).catch((error) => {
	// 		// setLoading(false);
	// 		toast.error(error.message);
	// 	})

	// 	setTimeout(() => {
	// 		setLoading(false);
	// 	}, 3000);
	// }

	//function delete serie
	// const handleDelete = (element: any) => {
	// 	swal({
	// 		title: `Are you sure, you want to delete this series ${element.series_voucher} ?`,
	// 		text: "Once deleted, the series will not be visible on the interface!",
	// 		icon: "warning",
	// 		buttons: ["Cancel", "Delete"],
	// 		dangerMode: true,
	// 	})
	// 		.then((willDelete: any) => {
	// 			if (willDelete) {
	// 				api.getHttpRequest(`/vouchers/delete/${element.series_voucher}`, data, 'delete').then((response) => {
	// 					if (response.data.status == 200) {
	// 						swal(response.data.message, {
	// 							icon: "success",
	// 						});
	// 						List();
	// 					} else {
	// 						swal(response.data.message, {
	// 							icon: "warning",
	// 						});
	// 					}
	// 				}).catch((error) => {
	// 					swal(error.message, {
	// 						icon: "warning",
	// 					});
	// 				})

	// 			} else {
	// 				swal("The series will be kept!");
	// 			}
	// 		});

	// }


	{/**
    function
 */}
	const getShortVouch = (r: any) => {
		return '**********' + r.substring((r.length - 4), r.length)
	}

	const getUsed = (x: any) => {
		if (x == 1) return ['badge light badge-danger', 'Already use'];
		if (x == 0) return ['badge light badge-success', 'Not use'];
		return '';
	}

	//Formate Date
	function geDateFormat(dateString: string): string {
		const date = new Date(Date.parse(dateString));
		const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
		const formattedDate = date.toLocaleDateString('en-US', options);
		return formattedDate;
	}

	//search to Input 
	const searchToInput = (event: any) => {
		const getSearch = event.target.value;
		if (getSearch.length > 0) {
			const searchdata = currentPosts.filter((item) => item.series_voucher.toLowerCase().includes(getSearch));
			setlistVoucher(searchdata)
		} else {
			setlistVoucher(searchResult)
		}
		setQuery(getSearch);
	}

	const options = [
		{
			label: 'USD',
			value: 'USD'
		},
		{
			label: `TZS-Tanzanian Shilling`,
			value: 'TZS'
		},
		{
			label: 'CDF-Congolese franc',
			value: 'CDF'
		}
	]

	// fucntion count serie voucher
	function countSeriesVoucher(array: any) {
		var count = 0;
		for (var i = 0; i < array.length; i++) {
			var obj = array[i];
			var series_voucher = obj.series_voucher;
			if (series_voucher) {
				count++;
			}
		}
		return count;
	}

	//Get current list page
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = listVoucher.slice(indexOfFirstPost, indexOfLastPost)
	const howManyPages = Math.ceil(listVoucher.length / postsPerPage);

	//Fuction qui me permet de faire le scrolle
	const [valueQrBar, setValueGrBar] = useState('1');

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValueGrBar(newValue);
	};

	//Antd table

	const expandedRowRender = () => {
		const columns: TableColumnsType<ExpandedDataType> = [
			{ title: 'Date', dataIndex: 'date', key: 'date' },
			{ title: 'Name', dataIndex: 'name', key: 'name' },
			{
				title: 'Status',
				key: 'state',
				render: () => <Badge status="success" text="Finished" />,
			},
			{ title: 'Upgrade Status', dataIndex: 'upgradeNum', key: 'upgradeNum' },
			{
				title: 'Action',
				dataIndex: 'operation',
				key: 'operation',
				render: () => (
					<Space size="middle">
						<a>Pause</a>
						<a>Stop</a>
						<Dropdown menu={{ items }}>
							<a>
								More <DownOutlined />
							</a>
						</Dropdown>
					</Space>
				),
			},
		];

		const data_ = [];
		for (let i = 0; i < 3; ++i) {
			data_.push({
				key: i.toString(),
				date: '2014-12-24 23:12:00',
				name: 'This is production name',
				upgradeNum: 'Upgraded: 56',
			});
		}
		return <Table columns={columns} dataSource={data_} pagination={false} />;
	};

	//   const columns: TableColumnsType<DataType> = [
	// 	{ title: 'Name', dataIndex: 'name', key: 'name' },
	// 	{ title: 'Platform', dataIndex: 'platform', key: 'platform' },
	// 	{ title: 'Version', dataIndex: 'version', key: 'version' },
	// 	{ title: 'Upgraded', dataIndex: 'upgradeNum', key: 'upgradeNum' },
	// 	{ title: 'Creator', dataIndex: 'creator', key: 'creator' },
	// 	{ title: 'Date', dataIndex: 'createdAt', key: 'createdAt' },
	// 	{ title: 'Action', key: 'operation', render: () => <a>Publish</a> },
	//   ];

	const data_1: DataType[] = [];
	for (let i = 0; i < 3; ++i) {
		data_1.push({
			key: i.toString(),
			name: 'Screen',
			platform: 'iOS',
			version: '10.3.4.5654',
			upgradeNum: 500,
			creator: 'Jack',
			createdAt: '2014-12-24 23:12:00',
		});
	}

	const dataVoucher = listVoucher.map((item, i) => ({
		...item,
		key: i
	}))

	const modifiedData = dataVoucher.map((body, ...item) => ({
		...item,
		// key : item.id, 
		message: body
	}))

	const handleDelete = (value: any) => {
		const dataSource = [...dataVoucher]
		const filteredData = dataSource.filter((item) => item.id !== value.id)
		setlistVoucher(filteredData)
	}

	// const handleUrl = (value: any) => {
	// 	const dataSource = [...dataVoucher]
	// 	const filteredData = dataSource.filter((item) => item.serie !== value.id)
	// 	navigate(`/secondary/${filteredData}`)

	// }

	const handleUrl = (record: any) => {
		const s = console.log(record.serie);
		navigate(`/secondary/${s}`)
	};

	const [selectedRowKeys, setSelectedRowKeys] = useState<RequestRow[]>([]);

	/** Fonction qui me permet d'initialiser mon checked */
	const createCheckedObject = (elements: RequestRow[], value: boolean) => {
		const checkedObject: Record<string, boolean> = {};
		for (const element of elements) {
			checkedObject[element.id] = value;
		}
		// Retourner l'objet créé
		return checkedObject;
	};



	const [checked, setChecked] = useState(createCheckedObject(selectedRowKeys, false));
	// Utiliser la fonction pour changer l'état checked avec la valeur true quand l'utilisateur coche une case
	const handleCheckbox = (record: RequestRow) => {
		const selectedRowKeysCopy = [...selectedRowKeys];
		if (selectedRowKeysCopy.includes(record)) {
			selectedRowKeysCopy.splice(selectedRowKeysCopy.indexOf(record), 1);
		} else {
			selectedRowKeysCopy.push(record);
		}
		setSelectedRowKeys(selectedRowKeysCopy);
		const key = record.id as keyof typeof checked;
		setChecked({ ...checked, [key]: true });
	};

	//Function Transfert voucher

	function AddVoucher(records: RequestRow[]) {
		// Vérifier si le tableau est vide
		if (records.length === 0) {
			toast.error('make sure to select the series before moving on to Transfer');
		} else {
			// Utiliser une boucle pour parcourir les éléments
			records.forEach((record) => {
				// Créer les données à envoyer
				let data = JSON.stringify({
					"observation": observation,
					"date_expedition": dateEpired,
					"id_country": idCountry,
					"serie_from": serieFrom,
					"serie_to": serieTo
				});
				setLoading(true);
				if (observation === '' || idCountry === '') {
					toast.error('Be sure to have completed the entire field');
					setLoading(false);
				} else {
					api.getHttpRequest('/voucher/transfere-serie', data, 'POST').then((response) => {
						if (response.data.status == 200) {
							toast.success(response.data.message);
							setLoading(false);
							// Typer la clé comme une des clés de checked
							const newCheckedObject = createCheckedObject(records, false);
							setChecked(newCheckedObject);
						} else {
							setLoading(false);
							toast.error(response.data.message);
						}
					}).catch((error) => {
						setLoading(false);
						toast.error(error.message);
					})
				}

			});
		}
	}

	const columnsVoucher: TableColumnsType<requestRow> = [

		{
			title: "#",
			dataIndex: "id",
		},
		// {
		// 	title: "Voucher_Serie",
		// 	render: (_, record) => record.voucher.serie,
		// 	align: "center",
		// },
		{
			title: "Serie",
			render: (_, record) => {
				const status = record.voucher.transfered ? "Transfer" : "not transfer";
				const color = record.voucher.transfered ? "green" : "red";
				return (
					<span >{record.voucher.serie} - <span style={{ color: color }}>{status}</span></span>
				);
			},
		},
		{
			title: "TransferedAt",
            render: (_, record) => api.formatDate(record.voucher.transferedAt)
		},
		{
			title: "Response",
			dataIndex: "response",
		},
		{
            title: 'Date_expedition',
            dataIndex: 'date_expedition',
            render: (date_expedition) => api.formatDate(date_expedition)
        },
	]

	function actualiser() {
		setSorterInfo({});
		// setSearchText("");
		List(1)
	};

	const handleChangeVoucher = (e: any) => {
		setSearchText(e.target.value);
		if (e.target.value === "") {
			List(limit);
		}
	};

	const globalSearch = () => {
		filteredData = dataVoucher.filter((value) => {
			return (
				value.serie === 'string' && value.serie.toLowerCase().includes(searchText.toLowerCase()) ||
				value.amount === 'string' && value.amount.toLowerCase().includes(searchText.toLowerCase())
			);
		});
		setlistVoucher(filteredData);
	};



	return (
		<>


			<div className="" style={{ width: '100%' }}>
				<div className="page-container">
					<Card style={{ width: '100%', opacity: 0.9, marginTop: 30 }}>



						<div style={{ margin: 10 }}>
							<div style={{ display: 'flex' }}>

								<div style={{ backgroundColor: '#F0F3F8', justifyItems: 'center', display: 'flex', padding: 15, width: 180, margin: 4, borderRadius: 5 }}>
									<span>Sount success : </span>
									<strong style={{ paddingLeft: 2 }}>{total}</strong>
								</div>
								<div style={{ backgroundColor: '#F0F3F8', justifyItems: 'center', display: 'flex', padding: 15, width: 180, margin: 4, borderRadius: 5 }}>
									<span>Not transfered : </span>
									<strong style={{ paddingLeft: 2 }}>{totalNotTransfered}</strong>
								</div>
							</div>
						</div>


						<div style={{ display: 'flex', marginTop: 20 }}>

							{/* <Col span={4}>
								<Space>
									<DeliveredProcedureOutlined
										style={{
											color: "green",
											backgroundColor: "rgba(0,255,0,0.25)",
											borderRadius: 20,
											fontSize: 24,
											padding: 2
										}}
									/>
									<div className="me-auto mb-sm-0 ">
										<h3>Count: {countSeriesVoucher(listVoucher)}</h3>
									</div>
								</Space>
							</Col> */}



							{/* <div >
										<Input size="large" placeholder="Searh here..." prefix={<SearchOutlined />}
											onChange={handleChangeVoucher}
											value={searchText}
											allowClear
											type='text'
										/>
									</div> */}
							
							<div style={{ display: 'flex' }}>
								<div style={{ marginRight: 2, marginLeft: 2 }}><label>limit</label></div>

								<select className="form-control" style={{ width: 120, height: 30 }}
									value={limit}
									onChange={(e) => setLimit(e.target.value)}
								>
									<option value="5" >5</option>
									<option value="10" >10</option>
									<option value="20" >20</option>
									<option value="30" >30</option>
									<option value="40" >40</option>
									<option value="50" >50</option>
									<option value="60" >60</option>
									<option value="70" >70</option>
									<option value="80" >80</option>
									<option value="90" >90</option>
									<option value="100" >100</option>
								</select>

								<div style={{ marginLeft: 2 }}>

									<button onClick={() => List(limit)} className="btn btn-rounded btn-primary" style={{ width: 120, height: 30, padding: 1 }}>Filtrer</button>

								</div>
							</div>


							{/* <button onClick={globalSearch} type="button" className="btn btn-rounded btn-primary" >

										Seach
									</button>

									<button onClick={actualiser} type="button" className="btn btn-rounded btn-primary" >

										Reset
									</button> */}

							

						</div>



						<div className="row" style={{ marginTop: 10 }}>
							<Toaster
								position="bottom-left"
								reverseOrder={false}
							/>
							<div className="col-lg-12">

								<Table
									columns={columnsVoucher}
									// expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
									dataSource={filteredData && filteredData.length ? filteredData : dataVoucher}
									size="small"
									loading={Loading}
									pagination={{

										total: totalPage,
										onChange: (page: any) => {
											List(page)
										}
									}}
									style={{ width: '100%' }}


								// expandable={{
								// 	rowExpandable: (record) => record.serie !== null,
								// 	expandedRowRender: (record) => {
								// 		if (record.serie) {
								// 			return <Table></Table>
								// 		}
								// 	}
								// }}
								/>

							</div>

						</div>
					</Card>
				</div>
			</div>



			<Modal
				title="Generate voucher"
				open={open}
				// onOk={handleOk}
				// confirmLoading={confirmLoading}
				onCancel={handleCancel}
				footer={null}
				width={1000}
			>
				<div className="settings-form">
					<div>
						<div className='row'>

							<div className="mb-3 col-md-4">
								<label className="form-label">Number</label>

								<input type="number" className="form-control" placeholder="Number"
									name='nombre'
									value={nombre}
									onChange={(e) => { setNombre(e.target.value) }}
									style={{ height: 40, paddingTop: 1 }}
								/>

							</div>
							<div className="mb-3 col-md-4">

								<label className="form-label">Amount</label>

								<input type="number" className="form-control" placeholder="Amount"
									name='valeur'
									value={valeur}
									onChange={(e) => { setValeur(e.target.value) }}
									style={{ height: 40, paddingLeft: 5 }}
								/>

							</div>

							<div className="mb-3 col-md-4">
								<label className="form-label">Currency</label>

								<select className="form-control" placeholder="number by packet"
									name='nombreByPacket'
									value={currency}
									onChange={(e) => { setCurrency(e.target.value) }}
									style={{ height: 40, paddingTop: 1 }}
								>
									<option value="" style={{ margin: 5 }}>Choose currency</option>
									{options.map((option, i) => (
										<option key={i} value={option.value}>{option.label}</option>
									))}
								</select>

							</div>

						</div>

						<div className='row'>

							<div className="mb-3 col-md-4">
								<label className="form-label">Number by packet</label>

								<input type="number" className="form-control" placeholder="Number by packet"
									name='nombreByPacket'
									value={nombreByPacket}
									onChange={(e) => { setNomberByPacket(e.target.value) }}
									style={{ height: 40, paddingTop: 1 }}
								/>

							</div>

							<div className="mb-3 col-md-4">
								<label className="form-label">Number of series</label>

								<input type="number" className="form-control" placeholder="Number of Series"
									name='nombreOfSerie'
									value={nombreOfSerie}
									onChange={(e) => { setNomberOfSerie(e.target.value) }}
									style={{ height: 40, paddingTop: 1 }}
								/>

							</div>

							<div className="mb-3 col-md-4">
								<label className="form-label">Label Name</label>

								<input type="text" className="form-control" placeholder="Label name"
									name='labelName'
									value={labelName}
									onChange={(e) => { setLabelName(e.target.value) }}
									style={{ height: 40, paddingTop: 1 }}
								/>

							</div>

						</div>

						<div className='row'>

							{/* <div className="mb-3 col-md-6"></div> */}
							<div className="mb-3 col-md-6">


								{!Loading && (
									<button  type="submit" className="btn btn-primary"
										style={{ width: 300, height: 30, padding: 2 }}
									>Generate</button>
								)}

								{Loading && (

									<button type="submit" className="btn-submit" disabled
										style={{ width: 300, height: 30, padding: 2 }}
									>
										<i className="fa fa-spinner fa-spin fa-3x  fa-lg"></i> <span>Ongoing processing...</span>
									</button>
								)}

							</div>
						</div>


					</div>
				</div>
			</Modal>

			<Modal
				title="Transfer Vouchers"
				open={openTrans}
				// onOk={handleOk}
				// confirmLoading={confirmLoading}
				onCancel={handleCancelTrans}
				footer={null}
			>
				<div className="settings-form">
					<div style={{ backgroundColor: '#F0F3F8' }} className="bg-[#F0F3F8] p-1 mb-2 rounded-md" role="alert">
						Are you sure you want to transfer your series? serie_from: <strong>{serieFrom}</strong> serieTo: <strong>{serieTo}</strong>
					</div>
					<div>
						<div className='row'>
							<div className="mb-2 col-md-6">
								<label className="form-label">Country name</label>

								<select className="form-control" placeholder="Number"
									name='nombre'
									onChange={(e) => { setIdCountry(e.target.value.toString()) }}
									style={{ height: 30, paddingTop: 1 }}
								>
									<option>Select country</option>
									{
										loadcountry.map((item, i) => (
											<option key={i} value={item.id}>{item.name}</option>
										))
									}
								</select>

							</div>
							<div className="mb-2 col-md-6">
								<label className="form-label">Date expedition</label>


								<input type="date" className="form-control" placeholder="Value"
									name='valeur'
									value={dateEpired}
									onChange={(e) => { setDateEpired(e.target.value) }}
									style={{ height: 30 }}
								/>

							</div>
						</div>

						<div className="mb-3 ">
							<label className="col-sm-3 col-form-label">Observation</label>
							<div className="col-sm-9 col-md-12">

								<textarea className="form-control" placeholder="Observation"
									name='nombreByPacket'
									value={observation}
									onChange={(e) => { setOservation(e.target.value) }}
								/>
							</div>
						</div>


						<div className="mb-3 ">
							<div className="col-sm-10">


								{!Loading && (
									<button style={{ width: 150, height: 30, padding: 2 }} onClick={() => AddVoucher(selectedRowKeys)} type="submit" className="btn btn-primary">Transfer</button>
								)}

								{Loading && (

									<button style={{ width: 250, height: 30, padding: 2 }} type="submit" className="btn-submit" disabled>
										<i className="fa fa-spinner fa-spin fa-3x  fa-lg"></i> <span>Ongoing processing...</span>
									</button>
								)}

							</div>
						</div>
					</div>
				</div>
			</Modal>

		</>
	)
}

export default HistoryTransfer
